import FormSection from '@/components/Forms/FormSection';
import {
  Box,
  Button,
  FormikSelectInput,
  FormikTextInput,
  Popover,
  SimulatedEventPayloadType,
  TextInput,
  useOpenClose,
} from '@palmetto/palmetto-components';
import { FieldArray, Field, useFormikContext } from 'formik';
import { HVACEquipmentDetails, HVACEquipmentType, HVACEquipmentTypeLabels } from 'types';

const EquipmentSection = ({ equipmentAvlSelection, avl, isReader }: any) => {
  const { isOpen, handleToggle, handleClose } = useOpenClose();
  const { values, setFieldValue } = useFormikContext<any>();
  return (
    <FormSection
      title="Equipment and Materials"
      description={
        <Box gap="xs">
          <Box>Update applicable equipment and materials for the proposal.</Box>
          <Box>NOTE: any customer facing price changes require a new proposal from the sales platform.</Box>
        </Box>
      }
    >
      <FieldArray
        name="equipment"
        render={(arrayHelpers) => {
          return (
            <Box gap="lg">
              {values.equipment && values.equipment.length
                ? values.equipment.map((e: HVACEquipmentDetails, index: number) => {
                    return (
                      <Box
                        key={`${e.model}-${index}`}
                        direction="row"
                        gap="lg"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Box direction="column" gap="sm" width="512px">
                          <Field
                            label="Equipment"
                            name={`equipment.${index}.option`}
                            id={`equipment.${index}.model`}
                            component={FormikSelectInput}
                            options={equipmentAvlSelection?.[e.type] || []}
                            isDisabled={isReader}
                            onChange={(event: SimulatedEventPayloadType) => {
                              const selectedOption = event.target.value.value;
                              const selectedEquipment = avl?.[e.type].find(
                                (item: any) => item.modelNumber === selectedOption,
                              );
                              setFieldValue(`equipment.${index}.name`, selectedEquipment?.name);
                              setFieldValue(`equipment.${index}.manufacturer`, selectedEquipment?.manufacturer);
                              setFieldValue(`equipment.${index}.model`, selectedEquipment?.modelNumber);
                              setFieldValue(`equipment.${index}.cost`, selectedEquipment?.cost ?? 0);
                              setFieldValue(`equipment.${index}.option`, event.target.value);
                            }}
                          />
                          <Box
                            fontSize="sm"
                            background="default"
                            direction="column"
                            borderWidth="xs"
                            borderColor="default"
                            padding="md"
                            radius="md"
                            gap="sm"
                          >
                            <Box as="h3">Equipment Details</Box>
                            <Box direction="row" gap="xs">
                              <Box fontWeight="bold">Type</Box>
                              <Box>{HVACEquipmentTypeLabels[e.type]}</Box>
                            </Box>
                            <Box direction="row" gap="xs">
                              <Box fontWeight="bold">Brand</Box>
                              <Box>{e.manufacturer}</Box>
                            </Box>
                            <Box direction="row" gap="xs">
                              <Box fontWeight="bold">Model</Box>
                              <Box>{e.model}</Box>
                            </Box>
                          </Box>
                        </Box>
                        <Field
                          label="Quantity"
                          type="number"
                          name={`equipment.${index}.quantity`}
                          id={`equipment.${index}.quantity`}
                          component={FormikTextInput}
                          maxWidth="lg"
                          width="100"
                          min={1}
                          isDisabled={isReader}
                          onChange={(event: SimulatedEventPayloadType) => {
                            const value = parseInt(event.target.value, 10);
                            if (value < 1) {
                              setFieldValue(`equipment.${index}.quantity`, 1);
                            } else {
                              setFieldValue(`equipment.${index}.quantity`, value);
                              const originalCost = (e.cost ?? 0) / (e.quantity || 1);
                              setFieldValue(`equipment.${index}.cost`, originalCost * value);
                            }
                          }}
                        />
                        <Box direction="column" gap="lg">
                          <TextInput
                            label="Total Cost"
                            name={`equipment.${index}.cost`}
                            id={`equipment.${index}.cost`}
                            value={e.cost}
                            type="number"
                            prefix="$"
                            width="xl"
                            isDisabled={isReader}
                            onFocus={(event: SimulatedEventPayloadType) => {
                              if (event.target.value === '0') {
                                setFieldValue(`equipment.${index}.cost`, '');
                              }
                            }}
                            onBlur={(event: SimulatedEventPayloadType) => {
                              const value = parseFloat(event.target.value);
                              setFieldValue(`equipment.${index}.cost`, isNaN(value) ? 0 : value);
                            }}
                            onChange={(event: SimulatedEventPayloadType) => {
                              const value = event.target.value;
                              setFieldValue(`equipment.${index}.cost`, value);
                            }}
                          />
                        </Box>
                        <Box padding="lg 0 0 0">
                          <Button
                            as="button"
                            variant="tertiary"
                            tone="danger"
                            isDisabled={isReader}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            Delete
                          </Button>
                        </Box>
                      </Box>
                    );
                  })
                : null}
              <Box alignItems="flex-start">
                <Popover
                  content={
                    <Box>
                      {Object.entries(HVACEquipmentType).map(([key, value]) => (
                        <Box
                          key={key}
                          as="button"
                          cursor="pointer"
                          type="button"
                          padding="md"
                          background="primary"
                          borderWidth="0"
                          color="contrast"
                          hover={{ background: 'default', color: 'contrast' }}
                          radius="sm"
                          textAlign="left"
                          onClick={() => {
                            handleClose();
                            arrayHelpers.push({
                              type: value,
                              manufacturer: '',
                              model: '',
                              quantity: 1,
                              cost: 0,
                            });
                          }}
                        >
                          {HVACEquipmentTypeLabels[value as HVACEquipmentType]}
                        </Box>
                      ))}
                    </Box>
                  }
                  placement="right-end"
                  isOpen={isOpen}
                  onClickOutside={handleClose}
                  contentContainerProps={{ background: 'primary', color: 'body-primary' }}
                >
                  <Button
                    as="button"
                    variant="secondary"
                    size="sm"
                    iconPrefix="add"
                    iconSuffix="caret-right"
                    isDisabled={isReader}
                    onClick={handleToggle}
                  >
                    Add Equipment
                  </Button>
                </Popover>
              </Box>
            </Box>
          );
        }}
      />
    </FormSection>
  );
};

export default EquipmentSection;
