import { ProgramType, BatchInfo } from 'types';
import { Box, Button } from '@palmetto/palmetto-components';
import { DateTime } from 'luxon';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { BatchComponent } from '@/types/Payouts';
import { useNavigate } from 'react-router-dom';
interface BatchesListProps {
  batches: BatchInfo[];
  programType: ProgramType;
  originComponent: BatchComponent;
}
export const BatchesList = ({ batches, programType, originComponent }: BatchesListProps) => {
  const actionButtonText = originComponent === BatchComponent.queued ? 'Review' : 'View';
  const navigate = useNavigate();
  return (
    <>
      {batches?.length > 0 ? (
        batches.map((batch) => (
          <Box background="primary" padding="lg lg" shadow="sm" radius="sm" gap="2xl" key={batch.id.toString()}>
            <Box direction="row" justifyContent="space-between" alignItems="center">
              <Box fontWeight="medium">
                <Box fontSize="md">
                  {DateTime.fromISO(batch.projectTransactionsCutoffDate).toFormat('cccc LLLL d, yyyy')}
                </Box>
                <Box fontSize="xs" as="span">
                  Payout Date
                </Box>
              </Box>

              <Box direction="row" alignItems="center" childGap="lg">
                <Box direction="column" alignItems="flex-end">
                  <Box fontSize="md" fontWeight="bold">
                    {batch.payees}
                  </Box>
                  <Box fontSize="xs">Payees</Box>
                </Box>

                <Box direction="column" alignItems="flex-end">
                  <Box fontSize="md" fontWeight="bold">
                    {batch.transactions}
                  </Box>
                  <Box fontSize="xs">Transactions</Box>
                </Box>

                <Box direction="column" alignItems="flex-end">
                  <Box fontSize="md" fontWeight="bold">
                    {currencyFormatter.format(batch.amount)}
                  </Box>
                  <Box fontSize="xs">Total Payout Amount</Box>
                </Box>
                <Button
                  navigate={() =>
                    navigate(`/admin/payouts/${programType}/group-batches/${batch.projectTransactionsCutoffDate}`)
                  }
                >
                  {actionButtonText}
                </Button>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <Box padding="lg" background="primary" borderWidth="1" borderColor="separator">
          No Batches available.
        </Box>
      )}
    </>
  );
};
