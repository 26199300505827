import { PayoutEvent, PayoutEventMap } from 'types';
import { Badge, Box, Icon, Table } from '@palmetto/palmetto-components';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ProjectTransactionClientData } from '@/types/Payouts';

interface ProjectTransactionTableProps {
  transactions: ProjectTransactionClientData[];
  selectedIds?: string[];
  handleRowSelected?(ids: string[], selected: boolean): void;
}
const flatLabelMap = Object.values(PayoutEventMap).flat();

const renderPayoutEventBadge = (event: PayoutEvent) => {
  const labelItem = flatLabelMap.find((item) => item.event === event);
  return <Badge variant={labelItem?.badgeVariant || 'default'} message={labelItem?.name || 'Unknown Event'} />;
};

const getTransactionColumns = () => [
  {
    heading: 'Payee',
    dataKey: 'payeeName',
    render: (cell?: any, _row?: any) => (
      <Box fontWeight="medium" as="span" display="inline">
        {cell}
      </Box>
    ),
  },
  {
    heading: 'Account',
    dataKey: 'projectName',
    render: (cell?: any, row?: any) => (
      <Box fontWeight="medium" as="span" display="inline">
        <Link to={`/accounts/${row['projectExternalId']}`}>
          {cell} <Icon name="c-caret-right" />
        </Link>
      </Box>
    ),
  },
  {
    heading: 'Payout Event',
    dataKey: 'event',
    render: (cell?: any) => (
      <Box fontWeight="medium" as="span" display="inline">
        {renderPayoutEventBadge(cell)}
      </Box>
    ),
  },
  {
    heading: <Box alignItems="flex-end">Amount</Box>,
    dataKey: 'amount',
    render: (cell?: any) => (
      <Box fontWeight="medium" alignItems="flex-end">
        {cell ? `$${cell.toFixed(2)}` : '-'}
      </Box>
    ),
  },
];

export const ProjectTransactionTable = ({
  transactions,
  selectedIds,
  handleRowSelected,
}: ProjectTransactionTableProps) => {
  const columns = useMemo(() => {
    const baseColumns = getTransactionColumns();
    if (handleRowSelected) {
      return [
        {
          heading: (
            <input
              type="checkbox"
              name="selectedTransaction"
              checked={Boolean(transactions?.length && transactions.length === selectedIds?.length)}
              onChange={() =>
                handleRowSelected(
                  transactions?.map((transaction) => `${transaction.id}`) || [],
                  !Boolean(transactions?.length && transactions.length === selectedIds?.length),
                )
              }
            />
          ),
          dataKey: 'id',
          render: (_cell?: any, row?: any) =>
            row ? (
              <input
                type="checkbox"
                name="selectedTransaction"
                checked={selectedIds?.includes(row.id)}
                onChange={() => handleRowSelected([row.id], Boolean(!selectedIds?.includes(row.id)))}
              />
            ) : (
              <></>
            ),
        },
        ...baseColumns,
      ];
    }
    return baseColumns;
  }, [handleRowSelected, transactions, selectedIds]);
  return transactions ? <Table rowKey="id" columns={columns} rows={transactions} /> : null;
};
