import { ObjectId } from 'mongodb';
import {
  DocumentDocument,
  MilestoneType,
  GlideECCoalTractEligibility,
  GlideECEligibility,
  Locale,
  SystemDesign,
  AccountMilestone,
  MilestoneTemplateType,
  AccountMilestoneStatus,
  AccountRequirementDocument,
  ProgramType,
  DisclosureDefinition,
  HVACSystemDesign,
  AttestmentData,
  SystemDesignReturnType,
} from './';
import { AccountStatus, Meta } from './Entities';

interface RequirementsWithRequirements extends AccountRequirementDocument {
  requirements: Array<AccountRequirementDocument>;
}

export interface CurrentMilestone {
  accountMilestoneId: ObjectId;
  name: string;
  order: number;
  type: MilestoneTemplateType;
  status: AccountMilestoneStatus;
  requirements: Array<RequirementsWithRequirements>;
}

export interface AccountMilestoneWithRequirements extends Omit<AccountMilestone, 'requirementRefs'> {
  requirements: Array<RequirementsWithRequirements>;
}

export type ProjectDates = Record<string, string>;

export enum CancelActivityRequesterType {
  customer = 'customer',
  organization = 'organization',
  internal = 'internal',
}

export enum CancelReason {
  financialConcerns = 'financialConcerns',
  ineligiblePropertyType = 'ineligiblePropertyType',
  choseDifferentFinancialProvider = 'choseDifferentFinancialProvider',
  unhappyWithSalesProcess = 'unhappyWithSalesProcess',
  reasonNotProvided = 'reasonNotProvided',
}

export const cancelReasonLabels = {
  [CancelReason.financialConcerns]: 'Financial Concerns',
  [CancelReason.ineligiblePropertyType]: 'Ineligible Property Type',
  [CancelReason.choseDifferentFinancialProvider]: 'Chose Different Financial Provider',
  [CancelReason.unhappyWithSalesProcess]: 'Unhappy With Sales Process',
  [CancelReason.reasonNotProvided]: 'Reason Not Provided',
};

export const cancelActivityRequesterTypeLabels = {
  [CancelActivityRequesterType.organization]: 'Organization',
  [CancelActivityRequesterType.customer]: 'Customer',
  [CancelActivityRequesterType.internal]: 'LightReach',
};

export enum CancelActivityType {
  cancellation = 'cancellation',
  reactivation = 'reactivation',
}

export enum CedProgramTypes {
  parallelPay = 'Lightreach v2024 2.0',
  postPay = 'Lightreach v2024 1.0',
}

export const CedProgramNameLabels = {
  [CedProgramTypes.parallelPay]: 'Parallel Pay',
  [CedProgramTypes.postPay]: 'Post Pay',
};

export enum InboundPaymentProvider {
  concord = 'concord',
  stripe = 'stripe',
}

export enum CEDParallelPayStatus {
  pending = 'pending',
  paid = 'paid',
  scheduled = 'scheduled',
}

export interface InboundPaymentProviderData {
  provider: InboundPaymentProvider;
  providerId?: string;
  providerUrl?: string;
  startDate: Date;
  existsInConcordsSystem?: boolean;
}

export interface InboundPaymentProviderHistory extends InboundPaymentProviderData {
  migrationDate: Date;
}

export interface Cancellation {
  activityType: CancelActivityType;
  isCancelled: boolean;
  notes?: string;
  requestedBy: string;
  requestedByDisplayName?: string;
  requestedByOrgAlias?: string;
  requestedByOrgDisplayName?: string;
  requestedDate: Date;
  requestHistory?: Cancellation[];
  requesterType: CancelActivityRequesterType;
  reason: CancelReason;
  supportingDocuments?: DocumentDocument[];
}

export interface BaseAccountDetails {
  externalReference?: string;
  externalReferenceIds?: Array<ExternalReferenceId>;
  programType?: string;
  friendlyName: string;
  address: Address;
  applicants?: Array<Applicant>;
  coordinates?: Coordinates;
  language?: Locale;
  utility: Utility;
  salesRepName: string;
  salesRepLicenseNumber?: string;
  salesRepEmail?: string;
  salesRepPhoneNumber?: string;
  notes?: AccountNotes;
}

export interface SolarAccountDetails extends BaseAccountDetails {
  /** @deprecated */
  systemDetails?: SystemDetails;
  systemDesign?: SystemDesignReturnType<ProgramType.solar>;
}

export interface HVACAccountDetails extends BaseAccountDetails {
  /** @deprecated */
  systemDetails?: HVACSystemDesign;
  systemDesign?: HVACSystemDesign;
}

export type AccountDetails<T extends ProgramType> = T extends 'hvac' ? HVACAccountDetails : SolarAccountDetails;

export type TCEDObject = {
  programName?: string;
  cedProjectId?: string;
  cedSplitPayEnabled: boolean;
  projectCreatedDate?: Date;
  parallelPaymentAmount?: number;
  parallelPaymentStatus?: CEDParallelPayStatus;
  shipConfirmationDate?: Date;
  spaPricingEnabled?: boolean;
};

export interface IElectricianSignOffDate {
  palmetto: Date;
}

export type AccountDocument<T extends ProgramType = any> = AccountDetails<T> & {
  id: ObjectId;
  organizationId: string;
  licensedOrganizationId?: string;
  materialsVendorOrgAlias?: string;
  status?: AccountStatus;
  stripePaymentMethodVerificationMethod?: VerificationMethod;
  stripeCustomerId?: string;
  stripePaymentMethodId?: string;
  stripeSetupIntentId?: string;
  priceSheetId?: string;
  primaryApplicantName?: string;
  /** @deprecated */
  systemDesigns?: Array<SystemDesign>;
  monitoringSiteId?: string;
  electricianSignoffDate?: IElectricianSignOffDate;
  ptoGrantedDate?: Date;
  itcAdderQualifications?: ITCAdderQualifications;
  meta?: Meta;
  allMilestonesComplete?: boolean;
  currentMilestone: CurrentMilestone;
  milestones: Array<AccountMilestoneWithRequirements>;
  priceSheetMappingId?: ObjectId;
  priceSheetMappingExpiresAt?: Date;
  permitSubmittedDate?: ProjectDates;
  permitApprovedDate?: ProjectDates;
  installScheduledDate?: ProjectDates;
  cancellation?: Cancellation;
  ced?: TCEDObject;
  inboundPayments?: InboundPaymentProviderData & {
    providerHistory: InboundPaymentProviderHistory[];
  };
  installer?: Installer;
  batteryMonitoringSiteId?: string;
  designTool?: string;
  noInverterConsumptionCT?: boolean;
  noBatteryConsumptionCT?: boolean;
  tranches?: string[];
  estimatedStatementGenerationDate?: string;
  firstPaymentDueDate?: string;
  firstPaymentDueDateTrigger?: string;
  orgEnergyCommunityModifierId?: ObjectId;
  orgHoldbackId?: ObjectId;
  orgPpwModifierId?: ObjectId;
  programType?: ProgramType;
  primaryApplicantCreditDOB?: string;
  permitAttestment?: AttestmentData;
};

export type Installer = {
  legalName: string;
  iccDocketNumber: string;
  qualifiedPersonOnsiteFirstName: string;
  qualifiedPersonOnsiteLastName: string;
  phoneNumber: string;
  email: string;
  address: string;
  city: string;
  state: string;
};

export enum VerificationMethod {
  instant = 'instant',
  skip = 'skip',
}

export type ApplicantDisclosure = Omit<DisclosureDefinition, 'versionNumber'> & { accepted?: boolean };

export interface Applicant {
  id?: ObjectId;
  type: ApplicantType;
  firstName: string;
  lastName: string;
  middleName?: string;
  phoneNumber: string;
  email: string;
  address: Address;
  disclosures?: Array<ApplicantDisclosure>; // default should be empty array
  creditDOB?: string;
}

export interface Address {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
}

export enum ApplicantType {
  primary = 'primary',
  secondary = 'secondary',
}

export type Coordinates = {
  lat: number;
  lon: number;
};

export interface ITCAdderQualifications {
  lowIncomeCommunityEligible: boolean;
  incomeCategory?: string;
  energyCommunityEligible: boolean;
  ecEligibilityCriteria?: GlideECEligibility;
  ecCoalTractEligibilityCriteria?: GlideECCoalTractEligibility;
  ecFfeCountyMsaName?: string;
  ecFfeCountyMsaType?: string;
  ecFfeCountyMsaCode?: string;
  potentialEligibilityQueryDate?: Date;
  finalEligibilityQueryDate?: Date;
}

export enum PropertyOwnershipStatus {
  manualVerificationRequired = 'manualVerificationRequired',
  unverified = 'unverified',
  verified = 'verified',
}

export interface Utility {
  lseId: number;
  utilityName?: string;
  tariffId?: number;
  rate?: number;
}

export interface SystemComponent {
  manufacturer: string;
  model: string;
  count: number;
}

export interface SystemDetails {
  systemFirstYearProductionKwh: number;
  systemSizeKw: number;
  /** @deprecated  use inverters[]*/
  inverterManufacturer?: string;
  /** @deprecated  use inverters[]*/
  inverterModel?: string;
  /** @deprecated  use inverters[]*/
  inverterCount?: number;
  inverters?: SystemComponent[];
  panelManufacturer?: string;
  panelModel?: string;
  panelCount?: number;
  mountingManufacturer?: string;
  mountingModel?: string;
  mountingType?: string;
  mountingCount?: number;
  storage?: SystemComponent[];
  /** @deprecated use storage[] */
  storageManufacturer?: string;
  /** @deprecated use storage[] */
  storageModel?: string;
  /** @deprecated use storage[] */
  storageCount?: number;
}

export enum AccountSearchField {
  address1 = 'address.address1',
  address2 = 'address.address2',
  addressCity = 'address.city',
  addressState = 'address.state',
  addressZIP = 'address.zip',
  applicantEmail = 'applicants.email',
  applicantFirstName = 'applicants.firstName',
  applicantLastName = 'applicants.lastName',
  externalReference = 'externalReference',
  friendlyName = 'friendlyName',
  organizationId = 'organizationId',
  primaryApplicantName = 'primaryApplicantName',
  salesRepName = 'salesRepName',
  status = 'status',
}

export interface AccountNotes {
  milestones: {
    [MilestoneType.install]: string;
  };
}

export enum ExternalReferenceType {
  concord = 'concord',
  /** Department of Energy */
  doe = 'DOE',
}

export interface ExternalReferenceId {
  id: string;
  type: ExternalReferenceType;
}

export interface IAccountService<T extends ProgramType> {
  programType: T;

  createAccount(organizationId: string, body: CreateAccountRequestV2<T>): Promise<AccountDocument<T>>;
  createAccountSideEffects(account: AccountDocument<T>): Promise<void>;
  getAccountById(accountId: string): Promise<AccountDocument<T>>;
  updateAccount(id: string, data: any, bypassValidation?: boolean): Promise<AccountDocument<T>>;
}

export type CreateAccountRequestV2<T extends ProgramType = any> = CreateAccountDetails<T> & {
  externalReference?: string;
  externalReferenceIds?: ExternalReferenceId[];
  programType: T;
  organizationId: string;
  friendlyName?: string;
  address: Address;
  applicants: Applicant[];
  coordinates?: Coordinates;
  language?: Locale;
  salesRepName: string;
  salesRepLicenseNumber?: string;
  salesRepEmail?: string;
  salesRepPhoneNumber?: string;
  systemDesign?: SystemDesignReturnType<T>;
};

export type HVACServicingContractor = {
  name: string;
  email?: string;
  phoneNumber?: string;
  address?: Address;
};

export type CreateHVACAccountDetails = {
  servicingContractor?: HVACServicingContractor;
};
export type CreateSolarAccountDetails = {
  utility: Utility;
  permitSubmittedDate?: ProjectDates;
  permitApprovedDate?: ProjectDates;
  installScheduledDate?: ProjectDates;
};

export type CreateAccountDetails<T extends ProgramType> = T extends 'hvac'
  ? CreateHVACAccountDetails
  : CreateSolarAccountDetails;
