import { CheckboxInput, useOpenClose } from '@palmetto/palmetto-components';
import { AttestmentData } from 'types';
import { useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { AttestmentModal } from '../ProductionValueCheck/AttestmentModal';

export const PermitAttestment = () => {
  const {
    isOpen: isAttestmentModalOpen,
    handleClose: handleCloseAttestmentModal,
    handleOpen: handleOpenAttestmentModal,
  } = useOpenClose();
  const { errors, handleBlur, isSubmitting, setFieldTouched, setFieldValue, values, touched } =
    useFormikContext<any>();
  const label =
    'I attest that I will apply for any and all necessary permits with the local authority having jurisdiction';

  async function handleAttestmentConfirmation(attestmentData: AttestmentData) {
    setFieldValue('attestmentText', attestmentData?.attestmentText);
    setFieldTouched('attestmentText', false, false);
    handleCloseAttestmentModal();
  }

  return (
    <FormSection title="Permit Attestation">
      <CheckboxInput
        id="permitAttestmendCheckbox"
        label={label}
        onChange={(event) =>
          event.target.checked ? handleOpenAttestmentModal() : setFieldValue('attestmentText', null)
        }
        isChecked={values.attestmentText ? true : false}
        isDisabled={isSubmitting}
        onBlur={handleBlur}
        error={touched?.attestmentText && (errors?.attestmentText as string)}
      />
      <AttestmentModal
        isOpen={isAttestmentModalOpen}
        onDismiss={handleCloseAttestmentModal}
        onAttestmentConfirmed={handleAttestmentConfirmation}
        label={label}
        title={'Permit Acknowledgement'}
        isLoading={isSubmitting}
      />
    </FormSection>
  );
};
