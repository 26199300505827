import { Alert, Badge, Box, Card, Spinner } from '@palmetto/palmetto-components';
import { ReactNode, useMemo, useState } from 'react';
import DateTimeDisplay from '../DateTime';
import { StatusIcon } from '../StatusIcon';
import ProjectDates from '../Account/ProjectDates';
import PauseBanner from './PauseBanner';
import {
  AccountStipulationType,
  Milestone,
  MilestoneStatus,
  MilestoneType,
  ProgramType,
  QueueStatus,
  QueueType,
} from 'types';
import { useGetAccountQuery } from '../../services/accounts';
import { useGetAccountStipulationsQuery } from '../../services/accounts';
import { useParams } from 'react-router-dom';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import { useGetQueueByAccountIdAndTypeQuery } from '@/services/queues';
import { getMilestoneLabel } from '@/helpers/getMilestoneLabel';

const filterOptionalPendingRequirements = (requirement: any) => {
  return requirement.isRequired || (!requirement.isRequired && requirement.status !== 'pending');
};

const SubTask = ({
  children,
  className,
  status,
}: {
  children: ReactNode;
  className?: string;
  status?: 'completed' | 'inProgress' | 'rejected' | 'pending';
}) => (
  <Box as="li" position="relative" className={className}>
    <Box direction="row" childGap="xs" alignItems="flex-start" position="relative">
      <Box background="primary">
        <StatusIcon status={status} size="md" />
      </Box>
      <Box>{children}</Box>
    </Box>
  </Box>
);

export function AccountMilestones({ className }: { className?: string }) {
  const { id } = useParams<{ id: any }>();
  const { data: account, isLoading } = useGetAccountQuery(id);
  const { data: stipulations } = useGetAccountStipulationsQuery(id);
  const { data: queue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.installPackage,
  });

  const [showDetails, setShowDetails] = useState(true);
  const isNonHvac = account?.programType !== ProgramType.hvac;
  const sortedMilestones = useMemo(() => {
    if (account?.milestones) {
      const milestoneCopy = [...account.milestones];
      return milestoneCopy.sort((a: any, b: any) => a.order - b.order);
    } else {
      return [];
    }
  }, [account]);

  const { milestonePausingDesignStipulations, hasUnsatisfiedPausingStipulations } = useMemo(() => {
    if (!stipulations) {
      return {};
    }
    return {
      milestonePausingDesignStipulations: stipulations.filter(
        (stipulation) =>
          stipulation.stipulationType === AccountStipulationType.designVerification && !stipulation.isSatisfied,
      ),
      hasUnsatisfiedPausingStipulations: stipulations.some(
        (stipulation) =>
          (stipulation.stipulationType === AccountStipulationType.administrative ||
            stipulation.stipulationType === AccountStipulationType.designVerification) &&
          !stipulation.isSatisfied,
      ),
    };
  }, [stipulations]);

  const milestoneStatuses = useMemo(() => {
    return account?.milestones?.reduce((acc: any, milestone: any) => {
      const hasSomeCompleted = milestone?.requirements?.some((req: any) => req.status === 'completed');
      const status =
        milestone?.status === 'rejected'
          ? 'rejected'
          : milestone?.status === 'paused'
            ? 'paused'
            : milestone?.status === 'submitted'
              ? 'submitted'
              : milestone?.status === 'approved'
                ? 'completed'
                : hasSomeCompleted
                  ? 'inProgress'
                  : milestone?.status === 'resubmitted'
                    ? 'resubmitted'
                    : undefined;
      acc[milestone.type] = status;
      return acc;
    }, {});
  }, [account]);

  const milestoneLabels = useMemo(() => {
    return (account?.milestones as Milestone[])?.reduce((acc: Record<string, string | undefined>, milestone) => {
      acc[milestone.type] = getMilestoneLabel(milestone, account);
      return acc;
    }, {});
  }, [account]);

  const exceptionInformation = account?.systemDesign?.toleranceException;
  const exception = account?.milestones?.[account?.milestones?.length - 1].exception;

  const internalException = exception;

  return (
    <Card className={className} position="relative">
      <Box
        padding="lg"
        childGap="md"
        direction={{ base: 'column', tablet: 'row' }}
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Box
          childGap="md"
          padding="2xs xs 0 0"
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
          width={{ base: '100', tablet: '20' }}
        >
          <Box alignItems="flex-start" gap="2xs">
            <Box as="h3" fontWeight="medium" fontSize="md" direction="row" gap="sm">
              Progress Tracker
              {account?.cancellation?.isCancelled && <Badge message="cancelled" variant="danger" />}
              {hasUnsatisfiedPausingStipulations && <Badge message="stipulation" variant="warning" />}
            </Box>
            <Box
              display="inline-block"
              as="button"
              type="button"
              borderWidth="0"
              background="transparent"
              color="body-primary"
              padding="2xs 0"
              fontSize="xs"
              cursor="pointer"
              hover={{ color: 'contrast' }}
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? 'Hide' : 'Show'} Details
            </Box>
          </Box>
          {showDetails && isNonHvac && <ProjectDates />}
        </Box>
        <Box as="ul" flex="auto" padding="0" direction={{ base: 'column', tablet: 'row' }}>
          {isLoading && (
            <Box flex="auto" justifyContent="center">
              <Spinner size="lg" />
            </Box>
          )}
          {!isLoading &&
            sortedMilestones.map((milestone: Milestone, index: number, array: any[]) => {
              const isLast = index + 1 === array.length;
              const isFirst = index === 0;
              const sortedRequirements = [...milestone.requirements]
                .filter(filterOptionalPendingRequirements)
                .sort((a: any, b: any) => a.order - b.order);
              const rejected = milestone.status === MilestoneStatus.rejected;
              const pending = milestone.status === MilestoneStatus.pending;
              const completed = Boolean(milestone?.completedAt).valueOf();
              return (
                <Box
                  flex="auto"
                  key={milestone?.type}
                  className={isLast ? '' : 'arrow-box'}
                  radius={isFirst ? 'sm 0 0 sm' : isLast ? '0 sm sm 0' : ''}
                  borderWidth={{ base: '0', tablet: isLast ? 'xs xs xs xs' : 'xs 0 xs xs' }}
                  padding={{ base: isLast ? 'sm 0 0 0' : 'sm 0', tablet: 'sm', desktop: 'md' }}
                  width={{ base: '100', tablet: '25' }}
                  gap="sm"
                  as="li"
                  borderColor="separator"
                >
                  {milestonePausingDesignStipulations?.map((stipulation) => {
                    return stipulation?.accountMilestoneType === milestone?.type ? (
                      <PauseBanner
                        key={`${stipulation?.id}`}
                        stipulationType={stipulation?.stipulationType}
                        description={stipulation?.description}
                      />
                    ) : null;
                  })}
                  {milestone?.name === 'Install' && exceptionInformation && (
                    <Alert
                      variant="warning"
                      hasIcon
                      title="Production Tolerance Exception"
                      message={
                        <RequirePalmettoFinanceUser
                          children={`${exceptionInformation.note} - ${exceptionInformation.exceptionGivenBy}`}
                        />
                      }
                    />
                  )}
                  {milestone?.name === 'Activation' && internalException && (
                    <RequirePalmettoFinanceUser>
                      <Alert
                        variant="warning"
                        hasIcon
                        title="[INTERNAL] Exception Note"
                        message={`${internalException.note} - ${internalException.exceptionGivenBy}`}
                      />
                    </RequirePalmettoFinanceUser>
                  )}
                  <Box direction="row" childGap="xs" alignItems="center" position="relative">
                    {milestoneStatuses && <StatusIcon status={milestoneStatuses[milestone?.type]} />}
                    <Box flex="auto">
                      <Box
                        fontSize={{ base: 'sm', tablet: 'xs', desktop: 'sm' }}
                        fontWeight="medium"
                        style={{ lineHeight: 'var(--size-line-height-heading)' }}
                      >
                        {milestone?.name}
                      </Box>
                    </Box>
                    <Box fontSize="sm" fontWeight="bold" color="grey-500">
                      {!completed && !pending && !rejected && milestoneLabels[milestone.type]}
                      {rejected && (
                        <Box as="span" color="danger-500">
                          {milestoneLabels[milestone.type]}
                        </Box>
                      )}
                      {milestone?.completedAt && (
                        <DateTimeDisplay value={milestone?.completedAt?.toString()} dateFormat={'DD'} excludeTime />
                      )}
                    </Box>
                  </Box>
                  {showDetails && (
                    <Box as="ul" padding="0 0 0 lg" childGap="xs" fontSize="xs">
                      {sortedRequirements
                        .sort((a: any, b: any) => a.order - b.order)
                        .map((req: any) => {
                          const requirements = req?.requirements || [];
                          const sortedSubRequirements = [...requirements]
                            ?.filter(filterOptionalPendingRequirements)
                            .sort((a: any, b: any) => a.order - b.order);
                          return (
                            <SubTask key={req.type} status={req.status}>
                              {req.name}
                              {sortedSubRequirements.length > 0 && (
                                <Box as="ul" padding="xs 0 0 0" childGap="xs" fontSize="xs">
                                  {sortedSubRequirements?.map((subReq: any) => (
                                    <SubTask key={subReq.type} status={subReq.status}>
                                      {subReq.name}
                                    </SubTask>
                                  ))}
                                </Box>
                              )}
                            </SubTask>
                          );
                        })}
                    </Box>
                  )}
                  {milestone?.name === 'Install' && queue && queue.status === QueueStatus.conditionallyApproved && (
                    <Alert
                      variant="warning"
                      hasIcon
                      title="Install Package was approved, but additional action(s) will be required at Activation Submission."
                    />
                  )}
                </Box>
              );
            })}
        </Box>
      </Box>
    </Card>
  );
}
