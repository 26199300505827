import { ObjectId } from 'mongodb';

export enum UserTypes {
  organizational = 'organizational',
  account = 'account',
  machine = 'machine',
  unknown = 'unknown',
}

export enum UserPermissions {
  admin = 'admin',
  canApproveInstall = 'canApproveInstall',
  canViewVendorInvoices = 'canViewVendorInvoices',
  lightReachPaymentAdmin = 'lightReachPaymentAdmin',
  lightReachSupport = 'lightReachSupport',
  lightReachOrgPaymentAdmin = 'lightReachOrgPaymentAdmin',
  lightReachOrgPaymentViewer = 'lightReachOrgPaymentViewer',
  lightReachAccountingAdmin = 'lightReachAccountingAdmin',
  lightReachAccountingApprover = 'lightReachAccountingApprover',
  lightReachRequirementAdmin = 'lightReachRequirementAdmin',
  lightReachCreditAdmin = 'lightReachCreditAdmin',
  pricingAdmin = 'pricingAdmin',
  read = 'read',
  write = 'write',
}

export interface User {
  permissions?: UserPermissions[];
  type?: UserTypes;
  organizationId?: string;
  rootOrganizationId?: string;
  accountIds?: ObjectId[];
  id?: string;
}

export interface userExtended extends User {
  username: string;
}

export interface Actor {
  authIdentity: string;
  user: userExtended;
}

export interface UserInfo {
  id: string;
  name: string;
}

export interface UserWithDate {
  user: UserInfo;
  date: Date;
}
