import { useEffect, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link, NavLink, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Badge, Box, Icon, toast } from '@palmetto/palmetto-components';
import { useGetAccountQuery } from '../../services/accounts';
import PageHeader from '../PageHeader';
import { AccountStatus } from '../../types/Accounts';
import { GetInviteLinkModal } from './GetInviteLinkModal';
import Overview from './Overview';
import History from './History';
import { MainContainer } from '../MainContainer';
import { getRemainingRequirements } from '../../helpers/getRemainingRequirements';
import { AddersTab } from '../Adders/AddersTab';
import { useGetQuotesQuery } from '../../services/quotes';
import { QuoteStatus } from '../../types/Quotes';
import CancelledBanner from '../Cancel/CancelledBanner';
import AdminStipulationBannerList from '../Stipulations/AdminStipulationBannerList';
import { useHasEnabledFlag } from '../FlagGuard/Hook';
import { AccountDocument, ProgramType } from 'types';
import { SystemDesignFactory } from './SystemDesignFactory';
import QuoteCalculator from './HVACQuoteCalculator';

const inviteDisabledStatuses = [
  AccountStatus.created,
  AccountStatus.creditApproved,
  AccountStatus.creditApprovedWithStipulations,
  AccountStatus.creditDenied,
  AccountStatus.contractCreated,
];

/**
 * @description Returns whether we just voided a contract or not.
 */
export function useJustVoidedContract() {
  const location = useLocation();
  return () => {
    return (location && location.state && location.state['justVoidedContract']) || false;
  };
}

/**
 * @description Returns whether the 1-year estimate has been updated or not.
 */
export function useJustUpdated1YearEstimate() {
  const location = useLocation();
  return () => {
    return (location && location.state && location.state['justUpdated1yEstimate']) || false;
  };
}

const getSystemDesignLabel = (programType: ProgramType) => {
  switch (programType) {
    case ProgramType.hvac:
      return 'System Design';
    case ProgramType.doePr:
      return 'PV System';
    case ProgramType.newHomes:
      return 'PV System';
    case ProgramType.solar:
      return 'PV System';
    default:
      return '';
  }
};

const isSystemDesignMissing = (account: AccountDocument<any>) => {
  switch (account?.programType) {
    case ProgramType.hvac:
      const hvacAccount = account as AccountDocument<ProgramType.hvac>;
      return !hvacAccount.systemDesign || hvacAccount.systemDesign?.equipment?.length === 0;
    default:
      const solarAccount = account as AccountDocument<ProgramType.solar | ProgramType.doePr | ProgramType.newHomes>;
      return !solarAccount?.systemDesign?.systemSizeKw || !solarAccount?.systemDesign?.systemFirstYearProductionKwh;
  }
};

export function HandleToastDisplayComponent() {
  const hasVoidedAccountCheck = useJustVoidedContract();
  const hasUpdatedEstimateCheck = useJustUpdated1YearEstimate();
  const hasProdConfFlagEnabled = useHasEnabledFlag(['useProductionConfirmation']);
  const showVoidedContract = hasProdConfFlagEnabled() && hasVoidedAccountCheck();
  const showUpdated1YREstimate = hasProdConfFlagEnabled() && hasUpdatedEstimateCheck();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (showUpdated1YREstimate) {
      toast.success('Year-1 Production Estimate Updated', {
        style: {
          minWidth: 'fit-content',
        },
      });
    }
    if (showVoidedContract) {
      toast.success('Contract Voided', {
        style: {
          minWidth: 'fit-content',
        },
      });
    }
    if (showVoidedContract || showUpdated1YREstimate) {
      navigate(location, {
        replace: true,
      });
    }
  }, [showVoidedContract, showUpdated1YREstimate, navigate, location]);
  return null;
}

export function Account() {
  const { id } = useParams<{ id: any }>();
  const {
    resendConsumerInviteButtonEnabled,
    viewConsumerInviteLink,
    viewAccountActivity = false,
    viewAccountsSummary,
  } = useFlags();
  const { data: account, isLoading: isAccountLoading }: any = useGetAccountQuery(id);
  const { data: quotes, isLoading: isQuotesLoading } = useGetQuotesQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const activeQuote = quotes?.find((quote) => quote.status === QuoteStatus.active);
  const hasActiveQuoteAdders = activeQuote?.adders?.length > 0;
  const primaryAccountApplicant = account?.applicants.find((applicant: any) => applicant.type === 'primary');
  const isLoading = isAccountLoading || isQuotesLoading;
  const contractSent = !inviteDisabledStatuses.includes(account?.status);

  const remainingRequirements =
    useMemo(() => {
      if (account && !isAccountLoading) {
        const currentMilestone = account.milestones.find(
          (milestone: any) => milestone.type === account.currentMilestone.type,
        );
        return getRemainingRequirements(currentMilestone);
      }
    }, [isAccountLoading, account]) || [];

  const isHvac = account?.programType === ProgramType.hvac;

  return (
    <>
      <Helmet>
        <title>{`${account?.primaryApplicantName}`}</title>
      </Helmet>
      <MainContainer>
        {account?.cancellation?.isCancelled && <CancelledBanner cancellation={account?.cancellation} />}
        <AdminStipulationBannerList />
        <HandleToastDisplayComponent />
        <Box
          direction={{ base: 'column', tablet: 'row' }}
          childGap={{ base: 'sm', tablet: 'xl' }}
          alignaccounts={{ tablet: 'center' }}
        >
          <PageHeader
            eyebrow={<Link to="/accounts">Accounts</Link>}
            title={account?.primaryApplicantName ?? '...'}
            description={
              viewAccountsSummary &&
              remainingRequirements?.length > 0 && (
                <Box direction="row" padding="xs 0 0 0" wrap style={{ gap: 'var(--size-spacing-xs)' }}>
                  {remainingRequirements?.map((requirement) => (
                    <Badge
                      variant="warning"
                      key={`${requirement?.order}-${requirement?.type}`}
                      message={requirement?.name}
                      size={{ base: 'md', desktop: 'lg' }}
                    />
                  ))}
                </Box>
              )
            }
          />

          <Box padding={{ base: '0 lg', tablet: '0' }}>
            {(resendConsumerInviteButtonEnabled || viewConsumerInviteLink) && (
              <GetInviteLinkModal
                contractSent={contractSent}
                isLoading={isLoading}
                email={primaryAccountApplicant?.email}
              />
            )}
          </Box>
        </Box>

        <Box direction="row" borderWidth="0 0 xs 0" borderColor="separator">
          <NavLink to={`/accounts/${id}`} style={{ textDecoration: 'none', fontWeight: '500' }} end>
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Overview
              </Box>
            )}
          </NavLink>
          {isHvac && (
            <NavLink to={`/accounts/${id}/quote-calculator`} style={{ textDecoration: 'none', fontWeight: '500' }}>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Payment Calculator
                </Box>
              )}
            </NavLink>
          )}
          <NavLink to={`/accounts/${id}/system-design`} style={{ textDecoration: 'none', fontWeight: '500' }} end>
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
                direction="row"
                childGap="xs"
              >
                {!isLoading && isSystemDesignMissing(account) ? (
                  <Icon name="c-warning" size="md" color="danger" />
                ) : null}
                {getSystemDesignLabel(account?.programType)}
              </Box>
            )}
          </NavLink>
          {!isQuotesLoading && hasActiveQuoteAdders && (
            <NavLink to={`/accounts/${id}/adders`} style={{ textDecoration: 'none', fontWeight: '500' }} end>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  Adders
                </Box>
              )}
            </NavLink>
          )}
          {viewAccountActivity && (
            <NavLink to={`/accounts/${id}/history`} style={{ textDecoration: 'none', fontWeight: '500' }}>
              {({ isActive }) => (
                <Box
                  as="span"
                  padding="md"
                  className={isActive ? 'tab-active' : ''}
                  hover={{ color: 'primary' }}
                  style={{ transitionDuration: '0s' }}
                >
                  History
                </Box>
              )}
            </NavLink>
          )}
        </Box>

        <Routes>
          <Route path="/" element={<Overview />} />
          <Route path="/system-design" element={<SystemDesignFactory programType={account?.programType} />} />
          <Route path="/adders" element={<AddersTab selectedAdders={activeQuote?.adders} />} />
          {isHvac && <Route path="/quote-calculator" element={<QuoteCalculator account={account} />} />}
          {viewAccountActivity && <Route path="/history" element={<History />} />}
        </Routes>
      </MainContainer>
    </>
  );
}
