import { ProgramType } from 'types';
import { api } from './api';

export const systemDesignApi = api.injectEndpoints({
  endpoints: (build) => ({
    createSystemDesign: build.mutation<any, { systemDesign: Record<string, any>; accountId: string }>({
      query: ({ systemDesign, accountId }) => ({
        url: `accounts/${accountId}/system-design`,
        method: 'POST',
        body: systemDesign,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'SystemDesign', accountId: args.accountId },
        { type: 'Accounts', id: args.accountId },
      ],
    }),
    createHVACSystemDesign: build.mutation<any, { systemDesign: Record<string, any>; accountId: string }>({
      query: ({ systemDesign, accountId }) => ({
        url: `accounts/${accountId}/system-design/hvac`,
        method: 'POST',
        body: systemDesign,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'SystemDesign', accountId: args.accountId },
        { type: 'Accounts', id: args.accountId },
      ],
    }),
    getCurrentDesign: build.query<any, { id: string; programType: ProgramType }>({
      query: ({ id, programType }) => ({
        url: `accounts/${id}/system-design/${programType === ProgramType.hvac ? 'hvac/' : ''}current`,
      }),
      providesTags: (_result, _error, args) => [{ type: 'SystemDesign', accountId: args }],
    }),
  }),
});

export const { useCreateSystemDesignMutation, useGetCurrentDesignQuery, useCreateHVACSystemDesignMutation } =
  systemDesignApi;
