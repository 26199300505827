import { FieldArray, Field, useFormikContext } from 'formik';
import FormSection from '@/components/Forms/FormSection';
import { Box, Button, FormikTextInput, SimulatedEventPayloadType, TextInput } from '@palmetto/palmetto-components';
import { HVACDiscountDetails } from 'types';

const DiscountsSection = ({ isReader }: any) => {
  const { values, setFieldValue } = useFormikContext<any>();
  return (
    <FormSection
      title="Discounts"
      description={
        <Box gap="xs">
          <Box>No wording yet</Box>
        </Box>
      }
    >
      <FieldArray
        name="discounts"
        render={(arrayHelpers) => (
          <Box gap="sm">
            {values.discounts && values.discounts.length
              ? values.discounts.map((discount: HVACDiscountDetails, index: number) => (
                  <Box
                    key={`discount-${index}`}
                    direction="row"
                    gap="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box direction="column" gap="sm" width="512px">
                      <Field
                        label={`Discount ${index + 1}`}
                        name={`discounts.${index}.name`}
                        id={`discounts.${index}.name`}
                        component={FormikTextInput}
                        placeholder="Enter Discount Name"
                        isDisabled={isReader}
                      />
                    </Box>
                    <Box width="lg"></Box>
                    <Box direction="column" gap="lg" width="xl" position="relative">
                      <TextInput
                        label="Total Discount"
                        name={`discounts.${index}.cost`}
                        id={`discounts.${index}.cost`}
                        value={discount.cost}
                        type="number"
                        prefix="- $"
                        width="xl"
                        isDisabled={isReader}
                        onFocus={(event: SimulatedEventPayloadType) => {
                          const value = parseFloat(event.target.value);
                          if (event.target.value === '0') {
                            setFieldValue(`discounts.${index}.cost`, '');
                          } else {
                            setFieldValue(`discounts.${index}.cost`, isNaN(value) ? 0 : value);
                          }
                        }}
                        onBlur={(event: SimulatedEventPayloadType) => {
                          const value = parseFloat(event.target.value);
                          setFieldValue(`discounts.${index}.cost`, isNaN(value) ? 0 : value);
                        }}
                        onChange={(event: SimulatedEventPayloadType) => {
                          const value = event.target.value;
                          setFieldValue(`discounts.${index}.cost`, `${value}`);
                        }}
                      />
                    </Box>
                    <Box padding="lg 0 0 0">
                      <Button
                        as="button"
                        variant="tertiary"
                        tone="danger"
                        isDisabled={isReader}
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                ))
              : null}
            <Box alignItems="flex-start">
              <Button
                as="button"
                variant="secondary"
                size="sm"
                iconPrefix="add"
                isDisabled={isReader}
                onClick={() =>
                  arrayHelpers.push({
                    name: '',
                    quantity: 1,
                    cost: 0,
                  })
                }
              >
                Add Discount
              </Button>
            </Box>
          </Box>
        )}
      />
    </FormSection>
  );
};

export default DiscountsSection;
