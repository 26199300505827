import { Box, SimulatedEventPayloadType, toast } from '@palmetto/palmetto-components';
import { useMemo, useState } from 'react';
import { Form, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { isObject, isString } from 'lodash';
import { ProgramType } from 'types';
import { Formik } from 'formik';
import { MainContainer } from '../MainContainer';
import FormSectionContainer from '../Forms/FormSectionContainer';
import { useLazyGetHVACPricingQuery } from '../../services/pricing';
import { useCreateQuoteMutation } from '../../services/quotes';
import {
  EscalatorRateSection,
  FormActionsSection,
  FormResultSection,
  IDataResponse,
  ISelectionOption,
  TermFormSection,
  TotalFinancedAmountFormSection,
} from './HVACQuoteCalculator.components';

function containsISelectionOption(this: yup.MixedSchema, message: string, elementsToCompare: ISelectionOption[]) {
  return this.test('containsISelectionOption', message, function (value) {
    const { path, createError } = this;
    const isComparisonArrayNotProvided = !Array.isArray(elementsToCompare);
    const valueNotProvided = typeof value === 'undefined';

    if (isComparisonArrayNotProvided) {
      return true;
    }

    if (valueNotProvided) {
      return true;
    }

    const found = elementsToCompare?.find((element) => {
      if (isObject(value)) {
        return (value as ISelectionOption).id === element.id;
      }
      if (isString(value)) {
        return (value as string) === element.value;
      }
      return false;
    });

    if (!found) {
      return createError({ path, message: message ?? 'The term is not valid' });
    }

    return true;
  });
}
//Added due conflicting checking when switching to different values programatically (form values dynamically set)
yup.addMethod(yup.mixed, 'containsISelectionOption', containsISelectionOption);

const QuoteCalculator = ({ account }: { account: any }) => {
  const [totalFinancedAmount, setTotalFinancedAmount] = useState('0');

  //The selected term or rate
  const [selectedTermOption, setSelectedTermOption] = useState<ISelectionOption>();
  const [selectedRateOption, setSelectedRateOption] = useState<ISelectionOption>();
  const [loadingHVACPricingData, setLoadingHVACPricingData] = useState<boolean>(false);
  const [creatingQuote, setCreatingQuote] = useState<boolean>(false);
  const navigate = useNavigate();

  const [triggerSearch, { data: _data = [] }] = useLazyGetHVACPricingQuery();

  const [loadedHVACPricingData, setLoadedHVACPricingData] = useState<IDataResponse[]>([]);

  const [createQuote] = useCreateQuoteMutation();

  async function handleSubmit(values: {
    term: ISelectionOption | undefined;
    rate: string | undefined;
    totalFinancedAmount: number;
  }) {
    if (chosenElement) {
      await handleCreationRequest();
    }
  }

  async function handleSearch() {
    const amount = parseFloat(totalFinancedAmount);
    if (isNaN(amount)) {
      return;
    }
    const loadingToastId = toast.loading('Loading information...');
    setLoadingHVACPricingData(true);
    const leastTimeWaitedAfterResultsGotten = 1000;
    try {
      const { data } = await triggerSearch({
        accountId: account.id,
        totalFinancedAmount: amount,
      });
      setTimeout(() => {
        toast.dismiss(loadingToastId);
        const informationToShow = data || [];
        if (!informationToShow?.length) {
          toast.error("Couldn't find matching products. Please try with another amount.");
        }
        setLoadedHVACPricingData(informationToShow);
        setLoadingHVACPricingData(false);
      }, leastTimeWaitedAfterResultsGotten);
    } catch (e) {
      setTimeout(() => {
        toast.dismiss(loadingToastId);
        toast.error('Something wrong happened loading the data. Please try again.');
        setLoadingHVACPricingData(false);
      }, leastTimeWaitedAfterResultsGotten);
    }
  }

  async function handleOnBlur() {
    if (!selectedRateOption && !selectedTermOption && !loadedHVACPricingData?.length && !loadingHVACPricingData) {
      handleSearch();
    }
  }

  async function handleCreationRequest() {
    if (!chosenElement && !totalFinancedAmount) {
      return;
    }

    const creationPayload = {
      quoteData: {
        productId: chosenElement?.productId,
        externalReference: account?.externalReference || undefined,
        totalFinancedAmount: totalFinancedAmount,
      },
      id: account.id,
      programType: ProgramType.hvac,
    } as any;

    setCreatingQuote(true);
    const loadingToastId = toast.loading('Creating quote...');
    try {
      await createQuote(creationPayload).unwrap();
      toast.dismiss(loadingToastId);
      toast.success('Successfully created quote.');
      navigate(`/accounts/${account.id}`);
    } catch (e) {
      let errorMessage: string = (e as any)?.data?.message || 'Something wrong happened. Please try again.';
      toast.dismiss(loadingToastId);
      toast.error(errorMessage);
    }
    setCreatingQuote(false);
  }

  const { rateOptions, termOptions, mappedTerms, mappedRates } = useMemo(() => {
    const mapHVACPricingDataToValues = function () {
      const mappedRateValues: {
        [index: string]: IDataResponse[];
      } = {};
      const mappedTermValues: {
        [index: string]: IDataResponse[];
      } = {};
      for (let i = 0; i < loadedHVACPricingData?.length; i++) {
        if (Array.isArray(mappedRateValues[loadedHVACPricingData[i].escalationRate])) {
          mappedRateValues[loadedHVACPricingData[i].escalationRate.toString()].push(loadedHVACPricingData[i]);
        } else {
          mappedRateValues[loadedHVACPricingData[i].escalationRate.toString()] = [loadedHVACPricingData[i]];
        }
        if (Array.isArray(mappedTermValues[loadedHVACPricingData[i].term])) {
          mappedTermValues[loadedHVACPricingData[i].term.toString()].push(loadedHVACPricingData[i]);
        } else {
          mappedTermValues[loadedHVACPricingData[i].term.toString()] = [loadedHVACPricingData[i]];
        }
      }
      return {
        mappedTermValues,
        mappedRateValues,
      };
    };
    const { mappedRateValues, mappedTermValues } = mapHVACPricingDataToValues();

    const sortEscalatorRateKeysByValue = function (rate1Key: string, rate2Key: string) {
      if (mappedRateValues[rate1Key][0].escalationRate > mappedRateValues[rate2Key][0].escalationRate) {
        return 1;
      }
      if (mappedRateValues[rate1Key][0].escalationRate < mappedRateValues[rate2Key][0].escalationRate) {
        return -1;
      }
      return 0;
    };

    const sortTermKeysByValue = function (term1Key: string, term2Key: string) {
      if (mappedTermValues[term1Key][0].term > mappedTermValues[term2Key][0].term) {
        return 1;
      }
      if (mappedTermValues[term1Key][0].term < mappedTermValues[term2Key][0].term) {
        return -1;
      }
      return 0;
    };

    const rateOptionsCalculated: ISelectionOption[] = Object.keys(mappedRateValues)
      .sort(sortEscalatorRateKeysByValue)
      .map((escalatorKey) => ({
        label: `${Number(mappedRateValues[escalatorKey][0].escalationRate * 100).toFixed(2)}%`,
        value: `${mappedRateValues[escalatorKey][0].escalationRate}`,
        id: `${mappedRateValues[escalatorKey][0].escalationRate}`,
      }));

    const termOptionsCalculated: ISelectionOption[] = Object.keys(mappedTermValues)
      .sort(sortTermKeysByValue)
      .map((element) => ({
        label: `${mappedTermValues[element][0].term} Years`,
        value: `${mappedTermValues[element][0].term}`,
        id: `${mappedTermValues[element][0].term}`,
      }));

    return {
      rateOptions: rateOptionsCalculated,
      termOptions: termOptionsCalculated,
      mappedTerms: mappedTermValues,
      mappedRates: mappedRateValues,
    };
  }, [loadedHVACPricingData]);

  const filteredValuesToDisplay = useMemo(() => {
    const filteredTermOptions = termOptions.filter((element) => {
      if (!selectedRateOption) {
        return true;
      }

      const foundTerm = mappedTerms[element.value].find((mappedElement) => {
        return `${mappedElement.escalationRate}` === selectedRateOption?.value;
      });

      return foundTerm;
    });

    const filteredRateOptions = rateOptions.filter((element) => {
      if (!selectedTermOption) {
        return true;
      }

      const foundRate = mappedRates[element.value].find((mappedElement) => {
        return `${mappedElement.term}` === selectedTermOption?.value;
      });
      return !!foundRate;
    });

    return {
      terms: filteredTermOptions,
      rates: filteredRateOptions,
    };
  }, [selectedRateOption, selectedTermOption, termOptions, rateOptions]);

  const initialFormValues = useMemo(() => {
    const filteredSelectedTerm = filteredValuesToDisplay.terms?.find((element) => {
      return element.id === selectedTermOption?.id;
    });
    const filteredSelectedRate = filteredValuesToDisplay.rates?.find((element) => {
      return element.id === selectedRateOption?.id;
    });

    const term = filteredSelectedTerm
      ? filteredSelectedTerm
      : filteredValuesToDisplay.terms.length == 1
        ? filteredValuesToDisplay.terms[0]
        : undefined;
    const rate =
      filteredValuesToDisplay.rates.length == 1
        ? filteredValuesToDisplay.rates[0]
        : filteredSelectedRate
          ? filteredSelectedRate
          : undefined;

    setSelectedRateOption(rate);
    setSelectedTermOption(term);
    return {
      term,
      rate: rate?.value,
      totalFinancedAmount: totalFinancedAmount || '0',
    };
  }, [filteredValuesToDisplay, selectedTermOption, selectedRateOption]);

  const chosenElement = useMemo(
    () =>
      loadedHVACPricingData.find((element) => {
        return (
          `${element.term}` === selectedTermOption?.value &&
          `${element.escalationRate}` === selectedRateOption?.value
        );
      }),
    [loadedHVACPricingData, selectedTermOption, selectedRateOption],
  );

  const calculatorValidationSchema = yup.object().shape({
    totalFinancedAmount: yup.string().required(),
    term: (yup.mixed() as any).containsISelectionOption('No valid option provided', filteredValuesToDisplay.terms),
    rate: (yup.mixed() as any).containsISelectionOption('No valid option provided', filteredValuesToDisplay.rates),
  });

  //Whether to show additional inputs or not.
  const showAdditionalInputs = Boolean(parseFloat(totalFinancedAmount) > 0 && loadedHVACPricingData?.length);

  return (
    <MainContainer>
      <Box as="h2" fontWeight="medium" fontSize="lg" padding={{ base: 'lg lg 0 lg', desktop: '0' }}>
        Quote Calculator
        <Box padding={{ base: 'lh 0 0 0' }}>
          <Formik
            initialValues={initialFormValues as any /**TODO */}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={calculatorValidationSchema}
            validateOnChange={true}
          >
            {(props) => (
              <Form style={{ width: '100%' }} onSubmit={props.handleSubmit}>
                <FormSectionContainer>
                  <TotalFinancedAmountFormSection
                    onBlur={handleOnBlur}
                    value={`${totalFinancedAmount}`}
                    onChange={(stringValue) => {
                      setTotalFinancedAmount(stringValue);
                      props.setFieldValue('totalFinancedAmount', stringValue);
                      setLoadedHVACPricingData([]);
                      setSelectedRateOption(undefined);
                      setSelectedTermOption(undefined);
                    }}
                    disabled={loadingHVACPricingData || creatingQuote}
                  />
                  {showAdditionalInputs && (
                    <>
                      <TermFormSection
                        terms={filteredValuesToDisplay.terms}
                        onChange={(data: SimulatedEventPayloadType) => {
                          props.setFieldValue('term', data.target.value);
                          const filteredValue = filteredValuesToDisplay.terms.find(
                            (element) => element.value === data.target.value.value,
                          );
                          setSelectedTermOption(filteredValue);
                        }}
                      />
                      <EscalatorRateSection
                        rates={filteredValuesToDisplay.rates}
                        onClick={(selectedValue) => {
                          props.setFieldValue('term', selectedValue);
                          const filteredValue = filteredValuesToDisplay.rates.find(
                            (element) => element.value === selectedValue,
                          );
                          setSelectedRateOption(filteredValue);
                        }}
                      />
                      {chosenElement ? <FormResultSection data={chosenElement} /> : null}
                    </>
                  )}
                </FormSectionContainer>

                <FormActionsSection
                  isLoading={loadingHVACPricingData || creatingQuote}
                  selectedElement={chosenElement}
                  onCancel={() => {
                    navigate(`/accounts/${account.id}`);
                  }}
                />
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default QuoteCalculator;
