import { buildQueryString } from '@/helpers/buildQueryString';
import { api } from '@/services/api';
import { ProjectTransactionClientData } from '@/types/Payouts';
import { BatchInfo, IsoDateString } from 'types';

export const payoutsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjectTransactions: build.query<
      ProjectTransactionClientData[],
      { projectId?: string; groupId?: string; batchId?: string; payeeId?: string; externalId?: string }
    >({
      query: (args) => ({ url: `payouts/transactions?${buildQueryString(args)}` }),
      providesTags: (_result, _error, _args) => {
        return [{ type: 'ProjectTransactions', id: `LIST` }];
      },
    }),
    getBatches: build.query<
      BatchInfo[],
      { startCutoffDate: IsoDateString; endCutoffDate: IsoDateString; batchReviewStatus: string }
    >({
      query: ({ startCutoffDate, endCutoffDate, batchReviewStatus }) => ({
        url: `payouts/batches/?startCutoffDate=${startCutoffDate}&endCutoffDate=${endCutoffDate}&batchReviewStatus=${batchReviewStatus}`,
      }),
      providesTags: (_result, _error, _args) => {
        return [{ type: 'Batches', id: `LIST` }];
      },
    }),
    getBatchesByReviewStatus: build.query<BatchInfo[], { batchReviewStatus: string }>({
      query: ({ batchReviewStatus }) => ({
        url: `payouts/batches/?batchReviewStatus=${batchReviewStatus}`,
      }),
      providesTags: (_result, _error, _args) => {
        return [{ type: 'Batches', id: `LIST` }];
      },
    }),
    approveBatches: build.mutation<void, { batchIds: string[] }>({
      query: ({ batchIds }) => ({ url: `payouts/batches/approve`, method: 'POST', body: { batchIds } }),
      invalidatesTags: [
        { type: 'ProjectTransactions', id: 'LIST' },
        { type: 'Batches', id: `LIST` },
      ],
    }),
    unbatchTransaction: build.mutation<void, { transactionId: string }>({
      query: ({ transactionId }) => ({ url: `payouts/transactions/${transactionId}/unbatch`, method: 'POST' }),
      invalidatesTags: [
        { type: 'ProjectTransactions', id: 'LIST' },
        { type: 'Batches', id: `LIST` },
      ],
    }),
  }),
});

export const {
  useGetProjectTransactionsQuery,
  useApproveBatchesMutation,
  useGetBatchesQuery,
  useGetBatchesByReviewStatusQuery,
  useUnbatchTransactionMutation,
} = payoutsApi;
