import { useGetAccountQuery } from '@/services/accounts';
import { useGetQuotesQuery } from '@/services/quotes';
import { useCreateHVACSystemDesignMutation, useGetCurrentDesignQuery } from '@/services/systemDesign';
import { Form, Formik } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { Alert, Box, Button, Icon, Modal, toast, useOpenClose } from '@palmetto/palmetto-components';
import { useMemo, useState } from 'react';
import {
  HVACDiscountDetails,
  HVACEquipmentDetails,
  HVACEquipmentType,
  HVACInstallKitDetails,
  HvacPricingDetails,
  HVACServiceDetails,
  ProgramType,
  QuoteStatus,
  UserPermissions,
} from 'types';
import FormSection from '../Forms/FormSection';
import FormSectionContainer from '../Forms/FormSectionContainer';
import { useGetApprovedVendorsQuery } from '@/services/approvedVendors';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { MainContainer } from '../MainContainer';
import EquipmentSection from '../SystemDesign/hvac/EquipmentSection';
import InstallKitsSection from '../SystemDesign/hvac/InstallKitsSection';
import ServicesSection from '../SystemDesign/hvac/ServicesSection';
import DiscountsSection from '../SystemDesign/hvac/DiscountsSection';
import RequirePermissions from '../auth/requirePermissions';
import usePermissions from '@/hooks/usePermissions';

export const HVACSystemDesign = () => {
  const { id } = useParams<{ id: any }>();
  const { data: systemDesign }: any = useGetCurrentDesignQuery({
    id,
    programType: ProgramType.hvac,
  });
  const { data: avl } = useGetApprovedVendorsQuery({ programType: ProgramType.hvac });
  const { data: account }: any = useGetAccountQuery(id);
  const { data: quotes } = useGetQuotesQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const [createSystemDesign] = useCreateHVACSystemDesignMutation();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [formValues, setFormValues] = useState<any>({});
  const permissions = usePermissions();
  const isReader = permissions?.every((permission) => permission.includes(UserPermissions.read));

  const equipmentAvlSelection = useMemo(() => {
    if (!avl) return {} as Record<HVACEquipmentType, { label: string; value: string }[]>;
    const equipment: Record<HVACEquipmentType, { label: string; value: string }[]> = Object.keys(avl).reduce(
      (acc: any, key: string) => {
        acc[key as HVACEquipmentType] = avl[key as HVACEquipmentType].map((item: any) => ({
          label: item.name,
          value: item.modelNumber,
        }));
        return acc;
      },
      {} as Record<HVACEquipmentType, { label: string; value: string }[]>,
    );
    return equipment;
  }, [avl]);

  const systemEquipmentWithOption = useMemo(() => {
    if (!systemDesign?.equipment || !equipmentAvlSelection) return [];
    return systemDesign.equipment.map((e: HVACEquipmentDetails) => {
      const selectedItem = equipmentAvlSelection?.[e.type]?.find((item: any) => item.value === e.model);
      return {
        ...e,
        option: selectedItem,
      };
    });
  }, [systemDesign?.equipment, equipmentAvlSelection]);

  const activeQuote = quotes?.find((quote: any) => quote.status === QuoteStatus.active);

  const calculateTotalCost = (values: {
    equipment: HVACEquipmentDetails[];
    installKits: HVACInstallKitDetails[];
    services: HVACServiceDetails[];
    discounts: HVACDiscountDetails[];
  }) => {
    const equipmentCost = values.equipment.reduce((sum, item) => sum + Number(item.cost ?? 0), 0);
    const installKitsCost = values.installKits.reduce((sum, item) => sum + Number(item.cost ?? 0), 0);
    const servicesCost = values.services.reduce((sum, item) => sum + Number(item.cost ?? 0), 0);
    const discountsCost = values.discounts.reduce((sum, item) => sum + Number(item.cost ?? 0), 0);

    return equipmentCost + installKitsCost + servicesCost - discountsCost;
  };

  const handleSubmit = async (
    data: {
      services: HVACServiceDetails[];
      installKits: HVACInstallKitDetails[];
      equipment: HVACEquipmentDetails[];
      discounts: HVACDiscountDetails[];
    },
    { setSubmitting }: any,
  ) => {
    try {
      const totalSystemDesign = calculateTotalCost(data);

      if (activeQuote && totalSystemDesign !== activeQuote.totalSystemCost) {
        setFormValues(data);
        openModal();
        setSubmitting(false);
        return;
      }

      await createSystemDesign({
        systemDesign: {
          equipment: data.equipment,
          installKits: data?.installKits || [],
          services: data?.services || [],
          discounts: data?.discounts || [],
        },
        accountId: id,
      }).unwrap();
      toast.success('System design saved successfully');
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error saving system design');
    } finally {
      setSubmitting(false);
    }
  };

  const handleConfirmModalSubmit = async () => {
    closeModal();
    if (formValues) {
      try {
        await createSystemDesign({
          systemDesign: {
            equipment: formValues.equipment,
            installKits: formValues?.installKits || [],
            services: formValues?.services || [],
            discounts: formValues?.discounts || [],
          },
          accountId: id,
        }).unwrap();
        toast.success('System design saved successfully');
      } catch (e: any) {
        toast.error(e?.data?.message || 'Error saving system design');
      }
    }
  };

  return (
    <>
      <MainContainer>
        {!activeQuote ? (
          <Box childGap="md">
            <Alert variant="warning" message="A quote must be created and active before creating a System Design" />
            <Box fontWeight="bold" margin="0 0 sm 0">
              <Link to={`/accounts/${id}/quote-calculator`}>
                <Box direction="row" alignItems={'center'}>
                  <Icon name="caret-left" size="xs" color="primary" />
                  <span className="font-color-primary m-left-2xs">Create A Quote</span>
                </Box>
              </Link>
            </Box>
          </Box>
        ) : (
          <Box
            overflow="hidden"
            direction={{ base: 'column', desktop: 'row' }}
            childGap="lg"
            padding={{ base: '0', desktop: 'xl' }}
          >
            <Formik
              initialValues={{
                equipment: systemEquipmentWithOption,
                installKits: systemDesign?.installKits || [],
                services: systemDesign?.services || [],
                discounts: systemDesign?.discounts || [],
              }}
              onSubmit={handleSubmit}
              enableReinitialize={true}
              validateOnChange={false}
            >
              {({ isSubmitting, values }) => {
                const totalSystemDesignCost = calculateTotalCost(values);
                return (
                  <Form noValidate style={{ width: '100%' }}>
                    <Box as="h2" fontWeight="medium" fontSize="lg" padding={{ base: 'lg lg 0 lg', desktop: '0' }}>
                      HVAC System Design
                    </Box>
                    <FormSectionContainer>
                      <EquipmentSection
                        equipmentAvlSelection={equipmentAvlSelection}
                        avl={avl}
                        isReader={isReader}
                      />
                      <FormSection
                        title="Install Kits and Services"
                        description={
                          <Box gap="xs">
                            <Box>No wording yet </Box>
                          </Box>
                        }
                      >
                        <Box gap="lg">
                          <InstallKitsSection isReader={isReader} />
                          <ServicesSection isReader={isReader} />
                        </Box>
                      </FormSection>
                      <DiscountsSection isReader={isReader} />
                    </FormSectionContainer>
                    <Box alignItems="flex-end" margin="md">
                      <Box fontWeight="bold" fontSize="lg">
                        Total System Design Cost
                      </Box>
                      <Box fontWeight="bold" fontSize="lg">
                        {currencyFormatter.format(totalSystemDesignCost)}
                      </Box>
                    </Box>
                    <Box
                      direction={{
                        base: 'column',
                        tablet: 'row',
                      }}
                      childGap="sm"
                      style={{ flexShrink: 0 }}
                      padding={{ base: 'lg' }}
                      background="primary"
                    >
                      {activeQuote ? (
                        <Box alignSelf="flex-start" direction="row" gap="lg">
                          <Box gap="xs">
                            <Box fontSize="xl" fontWeight="bold">
                              {currencyFormatter.format(activeQuote.totalSystemCost)}
                            </Box>
                            <Box fontSize="md">Total Financed Amount</Box>
                          </Box>
                          <Box gap="xs">
                            <Box direction="row" alignItems="center" gap="2xs">
                              <Box fontSize="xl" fontWeight="bold">
                                {activeQuote.systemPricingDetails.find((d: HvacPricingDetails) => d.year === 1)
                                  ?.monthlyPayment || 0}
                              </Box>
                              <Box fontSize="md">/mo</Box>
                            </Box>
                            <Box fontSize="md">Customer Payment</Box>
                          </Box>
                          <Box gap="xs">
                            <Box direction="row" alignItems="center" gap="2xs">
                              <Box fontSize="xl" fontWeight="bold">
                                {activeQuote.systemPricingDetails.length}
                              </Box>
                              <Box fontSize="md">
                                {activeQuote.systemPricingDetails.length > 1 ? `years` : `year`}
                              </Box>
                            </Box>
                            <Box fontSize="md">Customer Term Length</Box>
                          </Box>
                        </Box>
                      ) : null}
                      <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
                        <Box style={{ marginLeft: 'auto' }} direction="row" gap="sm">
                          <Button
                            as="a"
                            href={`/accounts/${id ? id : ''}`}
                            variant="secondary"
                            tone="neutral"
                            size="md"
                            isLoading={isSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button size="md" variant="primary" type="submit" isLoading={isSubmitting}>
                            Save
                          </Button>
                        </Box>
                      </RequirePermissions>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        )}
      </MainContainer>

      <Modal ariaLabelledBy="costMistmatch" isOpen={isModalOpen} onDismiss={closeModal}>
        <Modal.Header id="costMistmatch" title="Cost Mismatch" onDismiss={closeModal} />
        <Modal.Body>
          The total system design cost does not match the active quote cost. Would you like to void the existing
          quote and create a new one?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" tone="neutral" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleConfirmModalSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
