import React, { useMemo } from 'react';
import { Box, Card, Spinner } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { useGetAccountQuery } from '../../services/accounts';
import { useGetQuotesQuery } from '../../services/quotes';
import { useGetContractsQuery } from '../../services/contract';
import { ContractListItem } from './ContractListItem';
import { ContractListData } from '../../types/Contracts';

export function Contracts({ className }: { className?: string }) {
  const { id } = useParams<{ id: any }>();
  const { data: account }: any = useGetAccountQuery(id);
  const { data: contracts, isLoading: isContractLoading } = useGetContractsQuery(id);
  const { data: quotes, isLoading: quotesIsLoading } = useGetQuotesQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const isLoading = quotesIsLoading || isContractLoading;

  const contractsQuotes = useMemo(() => {
    if (isLoading) return [];
    if (!contracts) return [];
    const contractData: Array<ContractListData> = [];
    for (const contract of contracts) {
      const quote = quotes?.find((q) => q.id === contract.quoteId);
      contractData.push({ quote, contract });
    }
    return contractData;
  }, [contracts, quotes, isLoading]);

  return (
    <>
      <Card className={className}>
        <Box childGap="lg" padding="lg" alignItems="center" direction="row" justifyContent="space-between">
          <Box as="h3" fontWeight="medium" fontSize="md">
            Contracts
          </Box>
        </Box>
        <ContractsList isLoading={isLoading} items={contractsQuotes} />
      </Card>
    </>
  );
}

export function ContractsList({ isLoading, items }: { isLoading: boolean; items: ContractListData[] }) {
  return (
    <Box width="100" overflow="auto">
      {!isLoading && items.length > 0 ? (
        <Box>
          {items.map((item, index) => (
            <ContractListItem item={item} key={String(item?.contract?.id) || index} />
          ))}
        </Box>
      ) : (
        <Box
          display="block"
          textAlign="center"
          padding="lg"
          childGap="xl"
          borderColor="separator"
          borderWidth="xs 0 0 0"
        >
          {isLoading ? (
            <Spinner size="lg" />
          ) : (
            <>
              <Box fontSize="sm" color="body-secondary">
                No contract exists for this account.
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
