import React from 'react';
import { Modal, Box, Button, Icon } from '@palmetto/palmetto-components';
import { DateTime } from 'luxon';
import { currencyFormatter } from '@/helpers/currencyFormatter';

interface ApproveBatchConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  amount: number;
  groupId: string;
}

const ApproveBatchConfirmationModal: React.FC<ApproveBatchConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  amount,
  groupId,
}) => {
  const formattedDate = DateTime.fromISO(groupId).toFormat('EEEE MMM d, yyyy');
  const summaryTextArray = `${currencyFormatter.format(amount)} on ${formattedDate}`.split(' ');
  const summaryTextGroups = [summaryTextArray.slice(0, 3).join(' '), summaryTextArray.slice(3).join(' ')];
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Confirm Payout Approval" onDismiss={onClose} maxWidth="5xl">
      <Modal.Body padding="0">
        <Box direction="row" childGap="sm" background="warning" padding="lg">
          <Box padding="sm">
            <Icon name="t-warning" size="xl" color="warning-500" />
          </Box>
          <Box>
            <Box childGap="md">
              <Box fontSize="md" fontWeight="bold">
                Confirm Payout Approval
              </Box>
              <Box>
                Once this payment is approved it cannot be undone. Please review the amount and date to ensure
                accuracy.
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal.Body>
      <Modal.Footer justifyContent="space-between">
        <Box>
          <Box>{summaryTextGroups[0]}</Box>
          <Box>{summaryTextGroups[1]}</Box>
        </Box>
        <Box alignContent="flex-end">
          <Box direction="row" childGap="sm">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirm}>
              Confirm Payout Approval
            </Button>
          </Box>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveBatchConfirmationModal;
