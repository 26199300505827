import { Box, Button } from '@palmetto/palmetto-components';
import { FilterBar } from '@/components/filters';
import { useCallback, useEffect, useMemo } from 'react';
import { AddFilterButtonDropdown } from '@/components/filters/AddFilterButton';
import { AppliedDataFilter, PayoutEventMap } from 'types';
import { DataFilterDisplay, DataFilterOperation } from '@/components/filters/FilterButton';
import { DataFilterType } from '@/components/filters/SearchableCheckboxFilterList';
import { ProjectTransactionClientData } from '@/types/Payouts';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { SortButtonDropdown, SortItem } from '@/components/filters/SortButton';

interface ProjectTransactionsFilterProps {
  transactions: ProjectTransactionClientData[];
  appliedFilters?: AppliedDataFilter[];
  setAppliedFilters: (filters: AppliedDataFilter[]) => void;
  searchParams?: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
  searchTerm?: string;
  advancedFilters?: string;
  appliedSort?: SortItem;
  handleSortSelection: (sort: SortItem) => void;
  exportUrl: string;
}
export const transactionSortOptions: { id: string; label: string }[] = [
  {
    id: 'payeeAsc',
    label: 'Payee A-Z',
  },
  { id: 'payeeDesc', label: 'Payee Z-A' },
  { id: 'amountAsc', label: 'Amount Low-High' },
  { id: 'amountDesc', label: 'Amount High-Low' },
  { id: 'accountAsc', label: 'Account A-Z' },
  { id: 'accountDesc', label: 'Account Z-A' },
  { id: 'eventAsc', label: 'Event A-Z' },
  { id: 'eventDesc', label: 'Event Z-A' },
];
const flatLabelMap = Object.values(PayoutEventMap).flat();

export const ProjectTransactionsFilter = ({
  transactions,
  setAppliedFilters,
  appliedFilters = [],
  searchParams = new URLSearchParams(),
  setSearchParams,
  searchTerm,
  advancedFilters = '',
  appliedSort,
  handleSortSelection,
  exportUrl,
}: ProjectTransactionsFilterProps) => {
  // const isFiltered = !!Array.from(searchParams.keys()).filter((key) => key !== 'sort').length;
  const filters: DataFilterDisplay[] = useMemo(() => {
    const valueSets = transactions?.reduce(
      (acc, cur) => {
        if (cur.payeeName) acc.payeeSet.add(cur.payeeName);
        if (cur.projectName) acc.accountNameSet.add(cur.projectName);
        if (cur.amount) acc.amountSet.add(cur.amount);
        if (cur.event) acc.eventSet.add(cur.event);
        return acc;
      },
      {
        payeeSet: new Set<string>(),
        accountNameSet: new Set<string>(),
        amountSet: new Set<number>(),
        eventSet: new Set<string>(),
      },
    );
    return [
      {
        id: 'payeeName',
        label: 'Payee',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal, DataFilterOperation.NotEqual],
        selectOptions: Array.from(valueSets.payeeSet).map((value) => ({ value, label: value })),
      },
      {
        id: 'projectName',
        label: 'Account',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal, DataFilterOperation.NotEqual],
        selectOptions: Array.from(valueSets?.accountNameSet).map((value) => ({ value, label: value })),
      },
      {
        id: 'amount',
        label: 'Amount',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal, DataFilterOperation.NotEqual],
        selectOptions: Array.from(valueSets?.amountSet).map((value) => ({
          value: `${value}`,
          label: value ? currencyFormatter.format(value) : '?',
        })),
      },
      {
        id: 'eventType',
        label: 'Event Type',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal, DataFilterOperation.NotEqual],
        selectOptions: Array.from(valueSets?.eventSet).map((value) => ({
          value,
          label: flatLabelMap.find((item) => item.event === value)?.name ?? 'Unknown Event',
        })),
      },
    ];
  }, [transactions]);

  const onSearchChange = useCallback(
    (newSearchTerm: string) => {
      if (newSearchTerm && newSearchTerm !== '') {
        searchParams.set('search', newSearchTerm);
      } else {
        searchParams.delete('search');
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleApplyAdvancedFilters = useCallback(
    (filtersToApply: AppliedDataFilter[]) => {
      if (filtersToApply.some((filter) => filter.selectedValues && filter.selectedValues.length)) {
        searchParams.set('advancedFilters', JSON.stringify(filtersToApply));
        setSearchParams(searchParams);
      }
      setAppliedFilters(filtersToApply);
    },
    [setAppliedFilters, searchParams, setSearchParams],
  );

  const handleRemoveAdvancedFilter = useCallback(
    (filterId: string) => {
      const newFilters = appliedFilters.filter((filter) => filter.id !== filterId);
      if (newFilters.length) {
        searchParams.set('advancedFilters', JSON.stringify(newFilters));
      } else {
        searchParams.delete('advancedFilters');
      }
      setSearchParams(searchParams);
      setAppliedFilters(newFilters);
    },
    [appliedFilters, setAppliedFilters, searchParams, setSearchParams],
  );

  const handleClearAdvancedFilters = useCallback(() => {
    searchParams.delete('advancedFilters');
    setSearchParams(searchParams);
    setAppliedFilters([]);
  }, [setAppliedFilters, searchParams, setSearchParams]);

  useEffect(() => {
    const filters = advancedFilters || JSON.stringify([]);
    setAppliedFilters(JSON.parse(filters));
  }, [advancedFilters, searchTerm]);

  return (
    <Box background="grey-100" radius="sm" direction="row" display="block">
      <FilterBar
        filtersState={{
          searchTerm,
        }}
        handleSearchTerm={onSearchChange}
        placeholder="Search by Payee, Account, Amount"
      >
        <Box direction="row" width="100" gap="md" alignItems="center">
          <AddFilterButtonDropdown
            dataFilters={filters}
            appliedFilters={appliedFilters}
            onRemoveFilter={handleRemoveAdvancedFilter}
            onApplyFilters={handleApplyAdvancedFilters}
            onClearFilters={handleClearAdvancedFilters}
          />
          <SortButtonDropdown
            appliedSort={appliedSort || transactionSortOptions[0]}
            sortOptions={transactionSortOptions}
            onSortChange={handleSortSelection}
            className="sortButton"
          />
          <Button as="a" href={exportUrl} target="_blank" rel="noopener noreferrer">
            Export
          </Button>
        </Box>
      </FilterBar>
    </Box>
  );
};
