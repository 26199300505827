import { Helmet } from 'react-helmet';
import {
  generatePath,
  matchPath,
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Box, SelectInput } from '@palmetto/palmetto-components';
import PageHeader from '../../PageHeader';
import { MainContainer } from '@/components';
import { PaymentPlans } from './PaymentPlans';
import { useEffect, useState } from 'react';
import { ProgramType, ProgramTypeLabels } from 'types';
import { PaymentPlan } from './PaymentPlan';
import { Queued } from './Queued';
import { Paid } from './Paid';
import { ProjectTransactionsView } from '@/components/Payouts/ProjectTransactionsView';

const baseRoute = '/admin/payouts/:programType';
const routes = {
  paymentPlans: 'payment-plans',
  splitPay: 'split-pay',
  paymentPlan: 'payment-plans/:id',
};
export function Payouts() {
  const params = useParams();
  const programTypesOptions = Object.entries(ProgramTypeLabels).map(([key, value]) => ({
    label: value,
    key,
    value,
  }));
  const [programType, setProgramType] = useState(programTypesOptions[0]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { programType: urlProgramType = '' } = params;
    const lastProgramType =
      programTypesOptions.find((option) => option.key === urlProgramType) || programTypesOptions[0];
    setProgramType(lastProgramType);
    const pathPattern =
      Object.values(routes).find((pattern: string) => matchPath(`${baseRoute}/${pattern}`, location.pathname)) || '';
    const path = generatePath(`${baseRoute}/${pathPattern}`, { ...params, programType: urlProgramType });
    navigate(path);
  }, []);

  useEffect(() => {
    const { programType: urlProgramType = '' } = params;
    if (programType.key !== urlProgramType) {
      const lastProgramType =
        programTypesOptions.find((option) => option.key === urlProgramType) || programTypesOptions[0];
      setProgramType(lastProgramType);
    }
  }, [params]);

  const handleProgramTypeChange = (event: any) => {
    setProgramType(event.target.value);
    const pathPattern =
      Object.values(routes).find((pattern: string) => matchPath(`${baseRoute}/${pattern}`, location.pathname)) || '';
    const path = generatePath(`${baseRoute}/${pathPattern}`, { ...params, programType: event.target.value.key });
    navigate(path);
  };

  return (
    <>
      <Helmet>
        <title>Payouts</title>
      </Helmet>
      <MainContainer maxWidth="100">
        <Box maxWidth="2xl">
          <SelectInput
            id="required"
            label="Program Type"
            value={programType}
            onChange={handleProgramTypeChange}
            options={programTypesOptions}
            menuPortalTarget={document.body}
          />
        </Box>
        <PageHeader title={`Payments`}></PageHeader>

        <Box direction="row" borderWidth="0 0 xs 0" borderColor="separator">
          <NavLink to={'queued'} style={{ textDecoration: 'none', fontWeight: '500' }} end>
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Queued
              </Box>
            )}
          </NavLink>
          <NavLink to={'paid'} style={{ textDecoration: 'none', fontWeight: '500' }} end>
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Paid
              </Box>
            )}
          </NavLink>
          <NavLink to={'payment-plans'} style={{ textDecoration: 'none', fontWeight: '500' }} end>
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Payment Plans
              </Box>
            )}
          </NavLink>
        </Box>
        <Routes>
          <Route path="payment-plans" element={<PaymentPlans programType={programType.key as ProgramType} />} />
          <Route path="payment-plans/:id" element={<PaymentPlan />} />
          <Route path="queued" element={<Queued programType={programType.key as ProgramType} />} />
          <Route path="paid" element={<Paid programType={programType.key as ProgramType} />} />
          <Route path="group-batches/:groupId" element={<ProjectTransactionsView />} />
        </Routes>
      </MainContainer>
    </>
  );
}
