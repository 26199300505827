import { Box, Button, Card, DateInput } from '@palmetto/palmetto-components';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { ProgramType, IsoDateString, BatchReviewStatus } from 'types';
import { useGetBatchesQuery } from '@/services/payouts';
import { BatchesList } from '../BatchesList';
import { BatchComponent } from '@/types/Payouts';
import RequirePalmettoFinanceUser from '@/components/auth/RequirePalmettoFinanceUser';
import getConfigValue from '@/config';

const api = getConfigValue('VITE_APP_API_BASE_URL');

export const Paid = ({ programType }: { programType: ProgramType }) => {
  const defaultStartDate = DateTime.now().minus({ days: 30 }).toJSDate();
  const defaultEndDate = DateTime.utc().toJSDate();

  const [startCutoffDate, setStartDate] = useState<Date | null>(defaultStartDate || null);
  const [endCutoffDate, setEndDate] = useState<Date | null>(defaultEndDate || null);

  const setDate = ([startDate, endDate]: [Date | null, Date | null]) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const { data: batches, isLoading: isLoadingBatches } = useGetBatchesQuery({
    startCutoffDate: startCutoffDate?.toISOString() as IsoDateString,
    endCutoffDate: endCutoffDate?.toISOString() as IsoDateString,
    batchReviewStatus: BatchReviewStatus.approved,
  });

  return (
    <>
      <Card>
        <Box
          padding="sm lg"
          childGap="xs"
          overflow="auto"
          background="tertiary"
          radius={{ base: '0', desktop: 'md' }}
          position="sticky"
          style={{ top: '0' }}
          zIndex="sticky"
          direction={{ base: 'column', tablet: 'row' }}
          justifyContent="space-between"
        >
          <Box>
            <DateInput
              datePickerProps={{
                onChange: (date) => setDate(date as [Date | null, Date | null]),
                selected: startCutoffDate,
                selectsRange: true,
                startDate: startCutoffDate,
                endDate: endCutoffDate,
              }}
              textInputProps={{
                onClear: handleClear,
                id: 'rangePickerDate',
                name: 'rangePickerDate',
                label: 'Select Date Range',
              }}
            />
          </Box>
          <RequirePalmettoFinanceUser>
            <Box justifyContent={'center'}>
              <Button
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                href={`${api}/payouts/batches/csv?startCutoffDate=${startCutoffDate?.toISOString()}&endCutoffDate=${endCutoffDate?.toISOString()}&batchReviewStatus=${BatchReviewStatus.approved}`}
              >
                Export
              </Button>
            </Box>
          </RequirePalmettoFinanceUser>
        </Box>
      </Card>
      {!isLoadingBatches && batches && (
        <BatchesList programType={programType} batches={batches || []} originComponent={BatchComponent.paid} />
      )}
    </>
  );
};
